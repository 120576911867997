<template>
  <div class="index">
    <van-nav-bar
      title="待办事项"
      left-text="返回"
      right-text="新建"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
      @click-right="routerPush('/index/create')"
    />
    <!-- <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="输入群发账号"
          shape="round"
          @search="onSearch"
          @clear="clearSerch"
      >
        <template #action >
          <div @click="routerPush('/contactMessageBatchSend/create')" style="    display: flex;
    align-items: center; color:#2b60dd;    font-size: 26px;">
            <van-icon style="font-size: 26px" name="search" />
          </div>
        </template>
      </van-search>
    </div> -->
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div>
            <a-avatar class="avatar" shape="square" :size="50" icon="user" :src="item.contact.avatar" />
          </div>
          <div class="info">
            <table>
              <tr>
                <th>客户名称</th>
                <td>{{ item.contact.name }}</td>
              </tr>
              <tr>
                <th>跟进时间</th>
                <td>{{ item.day }} {{item.time}}</td>
              </tr>
              <tr>
                <th>跟进内容</th>
                <td>{{ item.content }}</td>
              </tr>
            </table>
            <!-- <div class="name">{{ item.contact.name }}</div>
            <div class="time">{{ item.day }} {{item.time}}</div>
            <div class="time">{{ item.content }}</div> -->
          </div>
          <div class="hand">
            <van-button type="info" round size="mini" @click="pushUser(item.contactId)">查看客户</van-button>
          </div>
        </div>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script>
import { getCookie, pushUser } from '@/plugins/utils'
import { ContactTodoApi } from '@/api/contactTodo'
export default {
  data() {
    return {
      pushUser,
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
    pushUser(item) {
      var url = 'https://work.' + location.hostname.split(".")[1] + '.' + location.hostname.split(".")[2] + '/contact?agentId=' + getCookie('workbench_agentId') + '&contactId='+item.contactId
      window.location.href = url
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      ContactTodoApi.index({
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.data || [])
      })
    },
    naireDetails (item) {
      console.log(item)
      this.$router.push('/contact/screen', {})
    },
    onSearch () {},
    clearSerch () {},
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {
      // background: #f4f5f7;
      padding: 16px 12px;

      .list-item {
        margin-bottom: 12px;
        border: 1px solid transparent;
        padding: 12px;
        background-color: #fff;
        border-radius: 12px;
        font-size: 12px;
        color: #585757;
        position: relative;
        display: flex;
        // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

        .avatar {
          margin-right: 12px;
        }

        .info {
          flex: 1;
        }

        th {
          font-weight: 500;
          // padding-right: 6px;
          width: 3.2rem;
          vertical-align: top;
        }

        td {
          padding: 0;
        }

        td, th {
          padding-bottom: 1px;
        }

        .hand {
          text-align: right;
          margin-left: 10px;
          // width: 80px;
          display: flex;
          align-items: center;
        }

        /deep/.van-button--mini {
          padding: 0 8px;
        }
      }
      
  }
}
</style>
